import React, { useMemo } from "react";

import UserDropdown from "../../components/Dropdowns/UserDropdown";
import classNames from "classnames";
import styles from "@styles/components/Sidebar.module.scss";
import { useRouter } from "next/router";
import Link from "next/link";
import { useUser } from "hooks/useUser";

export default function Navbar() {
  const user = useUser();
  const router = useRouter();
  const isAdmin = useMemo(
    () => user?.groups.some((group: string) => group === "Admins"),
    [user]
  );

  return (
    <>
      {/* Navbar */}
      <nav className="hidden md:flex top-0 left-0 w-full z-10 md:flex-row md:flex-nowrap md:justify-start  items-center pt-16">
        <div className="w-full mx-auto items-center flex justify-end md:flex-nowrap flex-wrap pl-4 pr-14">
          {/* User */}
          <ul className="flex-col md:flex-row list-none items-center hidden md:flex">
            <UserDropdown />
          </ul>
        </div>
      </nav>
      {/* End Navbar */}
      <div className="flex w-screen flex-row sm:hidden gap-x-6 px-6 overflow-scroll items-center relative">
        <Link href="/">
          <a
            className={classNames(
              styles.menuItem,
              {
                [styles.menuItemActive]:
                  router.pathname === "/" ||
                  router.pathname.indexOf("/views") !== -1,
              },
              "min-w-max"
            )}
          >
            <i className="icon icon-stats"></i>
            Statistics
          </a>
        </Link>
        {(isAdmin || user?.allow_upload) &&
          <Link href={isAdmin ? "/film-manager" : "/film-manager/new-film"}>
            <a
              className={classNames(
                styles.menuItem,
                {
                  [styles.menuItemActive]:
                    router.pathname.indexOf("/film-manager") !== -1 &&
                    router.pathname.indexOf("/views") < 0,
                },
                "min-w-max"
              )}
            >
              <i className="icon icon-circle-arrow-up"></i>
              {isAdmin ? "Film Manager" : "Upload Film"}
            </a>
          </Link>
        }
        {isAdmin ? (
          <Link href="/ads">
            <a
              className={classNames(
                styles.menuItem,
                {
                  [styles.menuItemActive]:
                    router.pathname.indexOf("/ads") !== -1,
                },
                "min-w-max"
              )}
            >
              <i className="fa-solid fa-rectangle-ad"></i>
              Managment
            </a>
          </Link>
        ) : (
          <></>
        )}
        {isAdmin && (
          <>
            <div className="!w-px h-6 bg-white relative z-50 text-transparent">
              {" "}
              .
            </div>
            <Link href="/s3-files">
              <a className={classNames(styles.menuItem, "min-w-max")}>
                S3 Files
              </a>
            </Link>
          </>
        )}
      </div>
    </>
  );
}
