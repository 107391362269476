import { createContext, useMemo } from "react";
import { useSelector } from "react-redux";
import type { User } from "../types";
import { AuthState } from "store/reducers/auth";

export const UserContext = createContext<User | null>(null);

export const UserProvider = ({ children }) => {
  const user = useSelector<AuthState, User>((state) => state.user);

  const memoizedUser = useMemo(() => user, [user]);

  return (
    <UserContext.Provider value={memoizedUser}>{children}</UserContext.Provider>
  );
};