// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";
import { config } from "./src/config";

const SENTRY_DSN = config.environment !== "local" ?
  "https://d235cd21caf743ab92ed647bdf1e5363@o1078593.ingest.sentry.io/6402586" : "";

Sentry.init({
  dsn: SENTRY_DSN,
  tracesSampleRate: 1.0,
  environment: config.environment,
});
