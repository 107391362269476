import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_SUCCESS,
  LOGOUT_FAIL,
  LOAD_USER_SUCCESS,
  LOAD_USER_FAIL,
  AUTHENTICATED_SUCCESS,
  AUTHENTICATED_FAIL,
  REFRESH_SUCCESS,
  REFRESH_FAIL,
  SET_AUTH_LOADING,
  REMOVE_AUTH_LOADING,
  SET_ALERT,
} from './types';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useToastrAlert } from '../../hooks/useToastrAlert';

export const load_user = () => async (dispatch: any) => {
  try {
    const res = await fetch('/api/account/user', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    });

    const data = await res.json();

    if (res.status === 200) {
      dispatch({
        type: LOAD_USER_SUCCESS,
        payload: data,
      });
    } else {
      dispatch({
        type: LOAD_USER_FAIL,
      });
    }
  } catch (err) {
    dispatch({
      type: LOAD_USER_FAIL,
    });
  }
};

export const check_auth_status = () => async (dispatch: any) => {
  try {
    const res = await fetch('/api/account/verify', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    });

    if (res.status === 200) {
      dispatch({
        type: AUTHENTICATED_SUCCESS,
      });
      dispatch(load_user());
    } else {
      dispatch({
        type: AUTHENTICATED_FAIL,
      });
    }
  } catch (err) {
    dispatch({
      type: AUTHENTICATED_FAIL,
    });
  }
};

export const request_refresh = () => async (dispatch: any) => {
  try {
    const res = await axios('/api/account/refresh');

    if (res.status === 200) {
      dispatch({
        type: REFRESH_SUCCESS,
      });
      const { data } = res.data;
      const secure = process.env.ENVIRONMENT !== 'local';
      Cookies.set('access', data.access, { secure, expires: (60 * 60 * 24 * 30), sameSite: 'strict' });
      Cookies.set('refresh', data.refresh, { secure, expires: (60 * 60 * 24 * 6), sameSite: 'strict' });
      dispatch(check_auth_status());
    } else {
      dispatch({
        type: REFRESH_FAIL,
      });
    }
  } catch (err) {
    dispatch({
      type: REFRESH_FAIL,
    });
  }
};

export const login = (email: string, password: string) => async (dispatch: any) => {
  const toastrAlert = useToastrAlert();
  const body = {
    email,
    password,
  };


  dispatch({
    type: SET_AUTH_LOADING,
  });

  try {
    const res = await axios('/api/account/login', {
      method: 'POST',
      data: body,
    })
      .then((response) => {
        if (response.status === 200) {
          const { data } = response.data;
          const secure = process.env.NEXT_PUBLIC_ENVIRONMENT !== 'local';
          Cookies.set('access', data.access, { secure, expires: (60 * 60 * 24 * 30), sameSite: 'strict' });
          Cookies.set('refresh', data.refresh, { secure, expires: (60 * 60 * 24 * 6), sameSite: 'strict' });

          dispatch({
            type: LOGIN_SUCCESS,
          });
          dispatch(load_user());
        } else {
          toastrAlert.error('Invalid credentials, verify please.');
          dispatch({
            type: LOGIN_FAIL,
          });
          dispatch({
            type: SET_ALERT,
            payload: alert,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: LOGIN_FAIL,
        });
        dispatch({
          type: SET_ALERT,
          payload: alert,
        });
        if (error.response.status === 401) {
          toastrAlert.error('Invalid credentials, verify please.');
        } else {
          toastrAlert.error(error.response.data.error ?? 'Something went wrong, try again please.');
        }
      });
  } catch (err) {
    toastrAlert.error('Something went wrong, try again please.');
    dispatch({
      type: LOGIN_FAIL,
    });
    dispatch({
      type: SET_ALERT,
      payload: alert,
    });
  }

  dispatch({
    type: REMOVE_AUTH_LOADING,
  });
};

export const signup =
  ({
    name,
    email,
    password,
    browser,
    device,
    subscribedToNewsletter
  }: {
    name: string;
    email: string;
    password: string;
    browser: string;
    device: string;
    subscribedToNewsletter: boolean;
  }) =>
  async (dispatch: any) => {
    const toastrAlert = useToastrAlert();
    const body = {
      name,
      email,
      password,
      browser,
      device,
      subscribedToNewsletter
    };

    dispatch({
      type: SET_AUTH_LOADING,
    });

    try {
      const res = await axios("/api/account/signup", {
        method: "POST",
        data: body,
      })
        .then((response) => {
          if (response.status === 200) {
            const { data } = response.data;
            const secure = process.env.NEXT_PUBLIC_ENVIRONMENT !== "local";
            Cookies.set("access", data.data.jwt, {
              secure,
              expires: 60 * 60 * 24 * 30,
              sameSite: "strict",
            });
            Cookies.set("refresh", data.data.refresh, {
              secure,
              expires: 60 * 60 * 24 * 6,
              sameSite: "strict",
            });

            dispatch({
              type: LOGIN_SUCCESS,
            });
            dispatch(load_user());
          } else {
            toastrAlert.error("Invalid credentials, verify please.");
            dispatch({
              type: LOGIN_FAIL,
            });
            dispatch({
              type: SET_ALERT,
              payload: alert,
            });
          }
        })
        .catch((error) => {
          dispatch({
            type: LOGIN_FAIL,
          });
          dispatch({
            type: SET_ALERT,
            payload: alert,
          });
          if (error.response.status === 401) {
            toastrAlert.error("Invalid credentials, verify please.");
          } else {
            toastrAlert.error(error.response.data.error ?? "Something went wrong, try again please.");
          }
        });
    } catch (err) {
      toastrAlert.error("Something went wrong, try again please.");
      dispatch({
        type: LOGIN_FAIL,
      });
      dispatch({
        type: SET_ALERT,
        payload: alert,
      });
    }
  };

export const logout = () => async (dispatch: any) => {
  try {
    Cookies.remove('access');
    Cookies.remove('refresh');
    dispatch({
      type: LOGOUT_SUCCESS,
    });
  } catch (err) {
    dispatch({
      type: LOGOUT_FAIL,
    });
  }
};

export const setAlert = (alert: any) => async (dispatch: any) => {
  dispatch({
    type: SET_ALERT,
    payload: alert,
  });
};
