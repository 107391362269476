import { useEffect } from "react";

type UseOutsideCallbackType = (
  ref: React.RefObject<HTMLElement>,
  callback: () => void,
  excludedElements?: React.RefObject<HTMLElement>[]
) => void;

export const useOutsideCallback: UseOutsideCallbackType = (
  ref,
  callback,
  excludedElements = []
) => {
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (
        excludedElements.some(
          (excludedElement) =>
            excludedElement.current &&
            excludedElement.current.contains(event.target)
        )
      ) {
        return;
      }
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
};
