import { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useSelector, useDispatch } from 'react-redux';
import { check_auth_status } from '../store/actions/auth';

export { RouteGuard };

function RouteGuard({ children }: any) {
  const router = useRouter();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state: any) => state.isAuthenticated);
  const [authorized, setAuthorized] = useState(false);

  useEffect(() => {
    // on initial load - run auth check
    authCheck(router.asPath);

    // on route change start - hide page content by setting authorized to false
    const hideContent = () => setAuthorized(false);
    router.events.on('routeChangeStart', hideContent);

    // on route change complete - run auth check
    router.events.on('routeChangeComplete', authCheck);

    // unsubscribe from events in useEffect return function
    return () => {
      router.events.off('routeChangeStart', hideContent);
      router.events.off('routeChangeComplete', authCheck);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  function authCheck(url: string) {
    if (isAuthenticated) {
      dispatch(check_auth_status() as any);
    }
    // redirect to login page if accessing a private page and not logged in
    const publicPaths = ['/users/login', '/auth/login', '/auth/forgot-password', '/auth/password-reset', '/auth/signup'];
    const path = url.split('?')[0];

    if (!isAuthenticated && !publicPaths.includes(path) && !path.includes('password-reset')) {
      setAuthorized(false);
      router.push({
        pathname: '/auth/login',
        query: {},
      });
    } else {
      setAuthorized(true);
    }
  }

  return (authorized && children);
}
