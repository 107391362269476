import {toast} from 'react-toastify';
import {ToastContent, ToastOptions} from 'react-toastify/dist/types';
import {useMemo} from 'react';

export const useToastrAlert = () => {
  return {
    ...toast,
    info: (content: ToastContent<any>, options?: ToastOptions<{}> | undefined) => {
      toast.info(content, {
        icon: <i className="icon icon-circle-info"></i>,
        ...options,
      });
    },
    success: (content: ToastContent<any>, options?: ToastOptions<{}> | undefined) => {

      toast.success(content, {
        icon: <i className="icon icon-check-white"></i>,
        ...options,
      });
    },
    warning: (content: ToastContent<any>, options?: ToastOptions<{}> | undefined) => {

      toast.warning(content, {
        icon: <i className="icon icon-circle-warning"></i>,
        ...options,
      });
    },
    error: (content: ToastContent<any>, options?: ToastOptions<{}> | undefined) => {

      toast.error(content, {
        icon: <i className="icon icon-circle-xmark"></i>,
        ...options,
      });
    },
  };
};
