import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";
import * as Sentry from "@sentry/nextjs";

const apiUrl = process.env.API_SERVER_URL ? process.env.API_SERVER_URL : process.env.NEXT_PUBLIC_API_SERVER_URL;

const retryLink = new RetryLink({
  delay: {
    initial: 500,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 3,
    retryIf: (error, _operation) => !!error,
  },
});

const errorLink = onError(({graphQLErrors, networkError, operation}) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({message, locations, path, cause}) => {
      const err = `[GraphQL error]: Message: ${ message }, Location: ${ locations }, Path: ${ path }`;
      console.error(err);
      if (cause) console.error(`[Error cause]: ${ cause }`);
      Sentry.captureException(err);
    });

  if (networkError) {
    console.error(`[Network error]: ${ networkError }`);
    Sentry.captureException(networkError);
  }

  console.error("Operation", operation.query, operation.variables);
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: ApolloLink.from([
    errorLink,
    retryLink,
    new HttpLink({uri: `${ apiUrl }/endpoint/preprgraphql/`}),
  ]),
});

export { client };
