import Router from 'next/router';
import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { persistor, store } from '../store/store';
import { PersistGate } from 'redux-persist/integration/react';
import '../styles/globals.scss';
import Overlay from '../components/uploader/overlay';
import { RouteGuard } from '@components/RouteGuard';
import Admin from '../layouts/Admin';
import { cssTransition, ToastContainer } from 'react-toastify';
import { ApolloProvider } from '@apollo/client';
import { client } from '@services/apollo-client.service';
import { UserProvider } from 'context/UserContext';

export default function App({ Component, pageProps }) {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const start = () => {
      setLoading(true);
    };
    const end = () => {
      setLoading(false);
    };
    Router.events.on('routeChangeStart', start);
    Router.events.on('routeChangeComplete', end);
    Router.events.on('routeChangeError', end);
    return () => {
      Router.events.off('routeChangeStart', start);
      Router.events.off('routeChangeComplete', end);
      Router.events.off('routeChangeError', end);
    };
  }, []);
  const getLayout = Component.getLayout || ((page) => {
    return <Admin>{ page }</Admin>;
  });


  const CloseButton = ({ closeToast }) => (
    <button className={ 'close-button' }>
      ✕
    </button>
  );

  const AlertAnimation = cssTransition({
    enter: 'animate__animated animate__fadeInUp',
    exit: 'animate__animated animate__fadeOutDown',
  });

  return (
    <Provider store={ store }>
      <PersistGate loading={ null } persistor={ persistor }>
        <ApolloProvider client={ client }>
          <UserProvider>
            <React.Fragment>
              <ToastContainer
                transition={ AlertAnimation }
                closeButton={ CloseButton }
                draggable={ false }
                hideProgressBar={ true }
                position={ 'bottom-right' }
                theme={ 'colored' }
              />

              { loading ? <Overlay extraClass="show"/> : <></> }
              {/*<NavBar/>*/ }
              {/*<Menu/>*/ }
              <RouteGuard>
                { getLayout(<Component { ...pageProps } />) }
              </RouteGuard>
            </React.Fragment>
          </UserProvider>
        </ApolloProvider>
      </PersistGate>
    </Provider>
  );
};
