import React from "react";

// components

import AdminNavbar from "../components/Navbars/AdminNavbar";
import Sidebar from "../components/Sidebar/Sidebar";

export default function Admin({ children }) {
  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64">
        <AdminNavbar />
        {/*-m-24*/}
        <div className="pt-4 pl-6 pr-6 md:pr-14 md:pl-0 mx-auto w-full">
          {children}
        </div>
      </div>
    </>
  );
}
